
.video-container{
    min-height: 100%; 
    width: 100%;
    display: flex;
    opacity: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
    .video-controlls {
        position: absolute;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: row;
        gap: 50px 30px;
        z-index: 8;
        bottom: 24%;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 40px;
        .video-controlls__button {
            width: 100px;
            height: 100px;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor:pointer;
            gap: 10px;
            .video-controlls__img {
                background-color: rgb(0, 0, 0);
                border-radius: 100%;
                width: 100px;
                height: 100px;
                min-width: 100px;
                min-height: 100px;
            }
            .video-controlls__txt {
                color: white
            }
        }
        .video-controlls__button--skip {
            .video-controlls__img {
                // background-color: orange;
                background-color: rgba(0, 0, 0, 0);
                border: 4px solid white;
            } 
        }
        .video-controlls__button--continue{
            .video-controlls__img {
                background-color: var(--color-primary);
            }
        }
    }
    
    .video-controlls-background {
        background-color: rgba(20, 26, 67, 0.594);
        min-height: calc(var(--vh, 1vh) * 100); 
        height:100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        cursor:pointer;
        opacity: 1;
    }
    .video-controlls-background--hide {
        opacity: 0;
    }
    #video-player {
        display: flex;
        min-width: 100%!important;
        // min-height: 100% !important;
        // border-radius: 20px;
        background-color: black !important; 
        video {
            width: 100%;  
            height: 100%;
            min-height: 100%; 
            object-fit: contain;
            background-color: black !important; 
            }
    }
}

.fullScreen {
    min-height: calc(var(--vh, 1vh) * 100); 
    #video-player {
        min-height: calc(var(--vh, 1vh) * 100); 
    }
}


.disableClick {
    pointer-events: none;
}

.seekBar {
    position: absolute;
    background-color: rgb(31, 31, 31);
    bottom: 0;
    padding:0;
    margin:0;
    width: 100%;
    height: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    .seekbar-played {
        transition: width 200ms ease-in-out;
        height: 100%;
        background-color: rgba(152, 152, 152, 0.317);
        position: absolute;
    }
}