.LessonVideoItem {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .LessonVideoItem-title {
        font-size: 22px;
        font-weight: 500;
        text-align: center;
        margin-top:16px;
    }
    .LessonVideoItem-title_current {
        color: white;
    }
    .LessonVideoItem-imageToShow {
        transition: transform 100ms ease-in-out;
        font-size: 24px;
        font-weight: 500;
    }
    &:hover {
        .LessonVideoItem-imageToShow {
            transform: scale(1.1);
            outline: white solid 4px;
            border-radius: 50%;

        }
    }
}

.video-icon-after {
    &:after {
        content: '';
        position: absolute;
        bottom:0px;
        inset-inline-start: 0;
        width: 44px;
        height: 44px;
        border: 1px solid black;
        border-radius: 100%;
        background-image: url("/assets/icons/play-icon.png");
        background-size: cover;
    }
}

.lesson-video-item__coin-completed {
    color: white;
    &:hover {
        .LessonVideoItem-imageToShow {
            transform: scale(1.1);
            outline: var(--color-gold) solid 4px;
            border-radius: 50%;

        }
    }
}

.lesson-video-item__coin-current {
    padding:0;margin:0;
    .lesson-video-item--Skillname {color: white}
    .lesson-video-item--smallText {color: rgba(255, 255, 255, 0.857)}
    
    .lesson-video-item--name {
        color: white;
    }
}

.lesson-video-item__coin--img {
    padding:0;margin:0;
    position: relative;
    width: 154px;
    min-width: 154px;
    height: 154px;
    min-height: 154px;
    outline: rgba(255, 255, 255, 0.352) solid 4px;
    border-radius: 50%;
    .lock-sticker {
        position: absolute;
        top: -10px;
        inset-inline-end: -10px;
    }
}

.lesson-video-item__coin--img_current {
    outline: none;
    &:before {
        padding: 2px;
        width: 160px;
        min-width: 160px;
        height: 160px;
        min-height: 160px;
        content: "";
        border-radius: 200px;
        top: 50%;
        left: 50%;
        outline: rgb(255, 255, 255) solid 5px;
        position: absolute;
        transform: translate(-50%, -50%);
        animation: breathCurrentCoinOutline 3s infinite;
    }
}
.lesson-video-item__coin--img_completed {
    outline: var(--color-gold) solid 8px;
}