.Xbutton {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 0; 
    margin: 0;
    z-index: 9999999999999;
}

.Xbutton.sizeM {
    width: 40px;
    height: 40px;
    min-width: 40px;
    padding: 0;
    img {
      width: 12px;
      height: 12px;
    }
  }

.Xbutton.fixed {
    position: fixed;
    margin: 5px;
}