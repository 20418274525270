.PWall_deal-container {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin: 10px 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .PWall_price-container {
        // gap: 20px;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 8px;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: start;
            align-items: start;
            gap: 0px;
        }
        .price_number {
            font-size: 40px;
            font-weight: 400;
            min-width: 50px;
            display: flex;
            align-items: baseline;
            gap: 2px;
        }
        .price_currency {
            font-size: 16px;
            font-weight: 400;
        }
    }
    .PWall_deal_plans-toggle-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2.5px;
        .PWall_deal_plans-toggle-container_text {
            padding-bottom: 4px;
        }
    }
}