:root {
  --topNavbar_background-color: white;
  --topNavbar_opacity: 1;
}


body {
  &[lang="he"] {
    .top-navbar {
      direction: rtl;
    }
    .language__button {
      direction: rtl;
    }
    .menu {
      direction: rtl;
    }
  }
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.top-navbar {
  z-index: 9;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  position: fixed;
  top: 0;
  // background-color: white;
  width: 100%;
  // border-bottom: 1px solid #ccc;
  padding: 12px 10px;
  color: var(--color-TEXT);
  font-weight: 100;
  // max-height: 62px;
  min-height: 80px;
  h1 {
    margin: 10px 20px;
    padding: 0;
    font-size: 25px;
    font-weight: 100;
    width: 100%;
    // max-width: 500px;
    min-width: 200px;
    font-weight: 400;
    text-align: center;
  }
  .top-navbar-title__bright {
    color: white;
  }
  @media screen and (max-width: 768px) {
    padding: 4px 10px;
    .textWebNav {
      display: none;
    }  
  }
}
.top-navbar__bg {
  &:before {
    width: 100%;
    height: 90px;
    min-height: 90px;
    margin-top: -10px;
    content: "";
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    background-color: var(--topNavbar_background-color);
    filter: blur(1.5px);
    backdrop-filter: blur(10px);
    opacity: var(--topNavbar_opacity);
  }
}
.top-navbar-strip--remove {
  margin-top: -100px;
}
.top-navbar__no-border {
  border: none;
}
.top-navbar--spacer {
  width: 50px;
  height: 20px;
  content: "";
}
.top-navbar--leftSide {
  display: flex;
  width: 100%;
  gap: 20px;
  // div:nth-child(2){
  //   margin-right: 10px;
  // }
}
.top-navbar--center {
  margin: 0 auto;
  width: 100%;
  min-width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
}
.top-navbar--rightSide {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  // justify-content: flex-end;
  text-align: right;
  width: 100%;
  // div:nth-child(2){
  //   margin-left: 20px;
  // }
  .language__button {
    // background-color: #FA7A9A;
    // color: rgb(23, 43, 77);;
    // background-color: #fff;
    padding: 19px;
    font-size: 18px;
    text-align: right;
    // transition: color 120ms ease-in-out;
    &:hover {
      cursor: pointer;
      // color: var(--color-orange);
    }
    // background-color: #e9e9e9;
    // border: 1px solid rgb(23, 43, 77);;
  }
}

.top-navbar__button {
  background-color: var(--topNavbar_background-color) !important;
  color: var(--color-TEXT);
  width: 100%;
  // max-width: 140px;
  border: 1px solid rgba(0,0,0,0.11);
  height: 53px;
  max-height: 53px;
  cursor: pointer;
  margin-top: 5px;
  min-width: 160px;
}
.top-navbar__button--save {
  // min-width: 340px !important;
}
.top-navbar__button--color {
  color: white;
  background-color: var(--partnerColor) !important;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.2);
  // border-color : var(--partnerColor);
}

.top-navbar--circle , .top-navbar--circle__with-text{
  cursor: pointer;
  font-size: 18px; 
  padding-bottom: 5px;
  text-decoration: none;
  color: var(--color-TEXT);
  display: flex;
  align-items: center;
  background-color: var(--topNavbar_background-color);;
  width: 53px;
  max-width: 53px;
  min-width: 53px;
  height: 53px;
  max-height: 53px;
  border-radius: 53px;
  justify-content: center;
  border: 2px solid rgba(0,0,0,0.2);
  text-decoration: none;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.128);
  transition: filter 90ms ease-in-out;
  &:hover {
    filter: brightness(1.1);
  }
  .left-chevron {
    font-size: 40px;
  }
  .settings-icon {
    font-size: 23px;
    padding-top: 5px;
    // background-image: url(ic40_trash@2x.png);
    // background-size: 10px 10px;
    // background-repeat: no-repeat;
    // background-position: center;  
  }
  .textWebNav {
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
  }
  
}
.top-navbar--feedback{
  // background-color: red;
  // margin: 0 50px ;
  .icon-feedback {
   max-width: 40px; 
  }
}

.top-navbar--circle__with-text--container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .top-navbar--circle__with-text {
      // background-color: #172b4d66;
      // padding: 20px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 20px;
      width: auto;
        // min-width: auto;
      img {
        padding-bottom: 5px;

        // background-color: blue;
        
      }
      span{
        background-color: red;
        padding: 0;
        padding-top: 5px;
        line-height: 20px;
      }
      @media screen and (min-width: 768px) {
        // border: none;
        
        // padding-right: 42px;
      }
    }
}

img.top-navbar--logo {
  width: 100px;
  margin-top: 5px;
  border-radius: 12px;
}
.top-navbar--menu {
  padding: 0 17px;
  width: 100%;
  min-width: fit-content;
  cursor: pointer;
  span {
    padding-bottom: 4px;
  }
  @media screen and (max-width: 768px) {
  }
}
.MuiDrawer-paperAnchorRight {
  direction: ltr;
}

.menu {
  width: 375px;
  // background: red;
  display: flex;
  flex-direction: column; 
  height: 100%;
  z-index: 9999999999;
  // width: 100%;
  @media screen and (max-width: 768px) {
    width: 100vw;

  }
  
  .menu__nav {
    display: flex;
    position: relative;
    border-bottom: 1px solid #e2e2e2;
    justify-content: center;
    min-height: 62px;
    align-items: center;
    position: fixed;
    min-width: 375px;
    background-color: white;
    z-index: 9999999;
    @media screen and (max-width: 768px) {
      width: 100vw;
    }
    h4 {
      font-size: 19px;
      color: var(--color-TEXT);
      line-height: 10px;
    }
    p{
      position: absolute;
      right: 16px;
      top: 0;
      color: var(--color-TEXT);
      cursor: pointer;
      transform: rotate(45deg);
      font-size: 30px;
      line-height: 0px;

    }
  }
  .menu__list {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: space-between; 
    height: 100%;
    width: 100%;
    z-index: 999;
    .menu__list--top {
      display: flex;
      flex-direction: column;
      //padding: 0 20px;
      .feedback-spot {
        padding-bottom: 8px;
        button {
          margin-bottom: 0px;
        }
        p{
          font-size: 15px;
          margin-top: 11px;
          color: #777;
        }
      }
    }
    .menu__list--bottom {
      display: flex;
      flex-direction: column;
      justify-self: end;
      padding-top: 30px;
      
      .menu__footer {
        font-size: 14px;
        color: #777;
        border-top: 1px solid #e2e2e2;
        padding: 15px 20px;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .menu__footer--lang {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 28px;
          padding: 5px;
          border: 1px solid #a9a9a9;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          &:hover {
            background-color: #e2e2e2;
          }
        }
      }
    }
    button {
      background-color: #FA7A9A;
      border-radius: 12px;
      margin: 6px 0;
      border: none;
      width: 100%;
    }
    
    .feedback__button {
      background-color: var(--color-orange);
    }
  }
}



.menu__links {
  display: flex;
  flex-direction : column;
  padding: 18px 20px; 
  gap: 10px;
  // border-top: 1px solid #e2e2e2;
  a {
    font-size: 18px;
    color: #172b4d;
    text-decoration: none; 
    padding: 18px 10px; 
    font-weight: 500;
    width: auto;
    background-color: #f2f2f2;
    cursor: pointer;
    &:hover {
      background-color: #f4f4f4;
    }
  }
  .user-email {
    font-size: 15px;
  }
}