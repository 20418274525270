$padding : 12px;

body {
    &[lang='he'] {
        .PWall_darkBG,.PWall_container, .PWall_container__nav, .PWall_container__body, .PWall_container__left, .PWall_container__right   {
            direction: rtl;
        }
        .PWall_darkBG .PWall_container .PWall_container__nav {
            flex-direction: row-reverse;
        }
    }
}


.PWall_darkBG{
    padding: 0;
    margin: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.486);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $padding;
    overflow: scroll;
    @media screen and (max-width: 768px) {
        align-items: start;
    }
    .PWall_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin: 0 auto;
        width: fit-content;
        height: fit-content;
        background-color: rgb(255, 255, 255);
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        box-shadow: 0px 9px 20px 0 rgba(0, 0, 0, 0.48);
        
        .PWall_container__nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            width: 100%;
            height: 50px;
            border-bottom: 1px solid #e2e2e2;
            .PWall_login-line {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 6px;
                .PWall_login-line_link {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .PWall_content {
            padding: 20px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            gap: 40px;
            .PWall_container__left {
                width: 100%;
                max-width: 440px;
                min-height: 100%;
                img {
                    width: 100%;
                    min-height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
            .PWall_container__right {
                width: 100%;
                text-align: start;
                max-width: 394px;
                .PWall_-title {
                    font-size: 30px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                .PWall_-description {
                    font-size: 18px;
                }
            }

            .PWall_footer {
                width: 100%;
                padding: $padding;
            }
        }
    }
}


.PWall_li {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    p {
        padding: 0;
        margin: 0;
    }
}

