.login-page-container {
    padding-top: 160px;
    @media screen and (max-width: 768px) {
        padding-top: 90px;
    }
}

.loginPage-error {
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.257);
    width: fit-content;
    padding: 10px 20px;
    font-size: 18px;
    margin-bottom: 20px;
}