.lesson-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(111, 111, 111);
    z-index: 0;
    &[lang='he'] {
        direction: rtl;
    }
  }
  .course {
    margin: 20px;
  }
  .lesson {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    z-index: 0;
  }
  .step {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .step-item {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  .completed {
    background-color: #b0e57c; 
  }
      
.celebration-container {
    z-index: 2;
    .Onboarding-subtitle {
        padding:0;
        z-index: 2;
    }
    .celebration-container--stepItem {
        pointer-events: none;
        // margin-top: 230px;
        position: absolute;
        top: 35%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        @media (max-width: 768px) {
            width: fit-content;
            min-width: fit-content;
            max-width: fit-content;
        }
        h2, p {padding: 0; margin: 0;}
        .ArrowPointer {display: none;}
        &::before {
            top: -80px !important;
            inset-inline-start: -80px !important;
            transform: scale(2);
            @media (max-width: 768px) {
                top: -30px !important;
                inset-inline-start: -40px !important;
                transform: scale(1);
            }
        }
        .lesson-step-item {
            transform: scale(2);
            @media (max-width: 768px) {
                gap: 4px;
                padding: 20px;
                transform: scale(1.3);
                width: fit-content;
                max-width: fit-content;
            }
            .lesson-step-item--name {
                @media (max-width: 768px) {
                    width: fit-content;
                    min-width: auto;
                }
                
            }
        }
    }
    .celebration-container--lessonItem {
        margin-top: 30px;
        z-index: 2;
        .LessonListItem__todo {display: none;}
        .ArrowPointer {display: none;}
    }
    
    
    .celebration-container--stepItem__completed {
        &:before {
            transition: opacity 100ms ease-in-out;
            opacity: 1;
            position: absolute;
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border-radius: 100%;
            border: 2px solid white;
            top: -10px;
            right: -10px;
            background-repeat: no-repeat;
            background-color: rgb(255, 255, 255);
            box-shadow: 0 3px 12px rgba(0, 0, 0, 0.191);
            border-radius: 100%;
            content: "";
            z-index: 9999999;
            background-image: url("/assets/icons/v-green-32.png");
            background-size: contain;
            
          }
    }
    .lesson-step-item__animal{ 
        &:before {
                inset-inline-start: -100px !important;
            @media (max-width: 768px) {
                inset-inline-start: -40px !important;
            }
        }
        .lesson-step-item {
            padding-inline-end: 30px;
        }
    }
    .lesson-step-item__coin {
        &:before {
            top: -60px !important;
            inset-inline-start: -150px !important;
            @media (max-width: 768px) {
                top: -30px !important;
                inset-inline-start: -40px !important;
            }
        }
    }
    .lesson-step-item__ImageToShow {
        padding: 20px;
        .lesson-step-item {
            transform: scale(1.4) !important;
            @media (max-width: 768px) {
                transform: scale(1) !important;
            }
        }
        &:before {
            top: -60px !important;
            inset-inline-start: -80px !important;
            @media (max-width: 768px) {
                top: 10px !important;
                inset-inline-start: 10px !important;
            }
        }
    }
}


.Lesson {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    min-height: calc(var(--vh, 1vh) * 100); 
    background-color: var(--page-background-color);
    z-index: 0;
    .Lesson--side-a {
        width: auto;
        min-width: 350px;
        height: 100px;
        min-height: calc(var(--vh, 1vh) * 100); 
        display: flex;
        flex-direction: column;
        // overflow-y: hidden;
        .LessonDetails-MOBILE {
            z-index: 99999;
            width: 100%;
            height: fit-content;
            // max-height: auto;
            // min-height: 330px;
            background-color: rgb(0, 0, 0);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.284);
            border-bottom: 1px solid rgba(0, 0, 0, 0.486);
            display: none;
            flex-direction: column;
            max-height: 300px;
            padding: 6px 12px;
            padding-bottom: 32px;
            gap: 10px;
            // border-radius: 20px 20px 0 0;
            transition: height 200ms ease-in-out;
            
            border-top: 2px solid rgba(0, 0, 0, 0.4);
            outline: 5px solid rgba(0, 0, 0, 0.12);
            position: relative;
            height: auto;
            background-color: var(--page-background-color);
            &:before{
                background-color: rgba(255, 255, 255, 0.3);
                content: "";
                height: 100%;
                width: 100%;
                position: absolute;
                top:0;
                left: 0;
                z-index: 0;
            }


            @media (max-width: 768px) {display: flex;}
            .LessonDetails-MOBILE__btn {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                padding: 12px;
                &:hover {
                    background-color: white;
                    color: black;
                }
            }
            .LessonDetails-MOBILE__top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: fit-content;
                transition: height 200ms ease-in-out;
            }
            .step-details--text {
                display: flex;
                gap:12px;
                z-index: 99999999;
                padding: 10px 12px;
                padding-bottom: 16px;
                flex-direction: column;
                .step-details--text__side-a{
                    max-width: 300px;
                    transition: height 200ms ease-in-out;
                    .step-details--name , .step-details--description{
                        // color: white;
                    }
                    .step-details--name{
                        font-size: 24px;
                        font-weight: 600;
                    }
                }
            }
            .lesson-video-thumbnail {
                width: 100px;
                height: 100px;
                min-height: 100px;
                min-width:  100px;
                max-width: 100px;
                padding: 4px 0;
                position: relative;
                z-index: 1;
                img {
                    background-color: var(--color-skyblue);
                    border-radius: 10px;
                    z-index: 1;
                    width: 100%;
                    // max-width: 240px;
                    // height: 150px;
                    // max-height: 150px;
                    // min-height: 150px;
                    height: 100%;
                    min-height: 100%;
                    object-fit: cover;
                    border: 1px solid black;
                }
                // &:before {
                //     background-image: url("/assets/icons/play-icon.png");
                //     background-size: contain;
                //     width: 36px;
                //     height: 36px;
                //     position: absolute;
                //     z-index: 2;
                //     content: "";
                //     top:28px;
                //     left:28px;
                //     transition: transform 200ms ease-in-out;
                //     transform: translate(-50%, -50%) scale(1);
                //     border: 1px solid rgb(123, 123, 123);
                //     border-radius: 100%;
                //     opacity: 0.92;
                // }
            }
        }
        .lesson-steps--container {
            padding: 30px 28px;
            padding-bottom: 68px;
            background-color: var(--page-background-color);
            max-width: 360px;
            max-width: 360px;
            gap: 20px;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            display: flex;
            overflow-y: auto;
            // height: fit-content;
            // min-height: fit-content;
            outline:none;
            padding-top: 52px;

            // @media (max-width: 768px) {
            //     padding-top:40px;
            // }
        }
        @media (max-width: 768px) {
            width: 100%;
            min-width: 100%;
            .lesson-steps--container{
                width: 100%;
                min-width: 0;
                max-width: 100%;
            }
        }
    }
    .Lesson--side-b {
        width: 100%;
        height: auto;
        background-color: var(--page-background-color);
        min-height: calc(var(--vh, 1vh) * 100); 
        display: flex;
        flex-direction: column;
        position: relative;
        height: calc(var(--vh, 1vh) * 100);
        overflow-y: scroll;
        .catbearsLogo-floating {
            position: fixed;
            top: 3px;
            left: 3px;
            z-index: 9999999;

        }
        .lesson-video--container {
            padding: 20px;
            width: 100%;
            height: calc(var(--vh, 1vh) * 70);
            position: relative;
            
            .lesson-video-thumbnail {
                background-color: rgba(0, 0, 0, 0.2);
                width: 100%;
                height: 100%;
                border-radius: 20px;
                z-index: 0;
                display: flex;
                position: relative;
                border: 1px solid rgba(0, 0, 0, 0.171);
                img {
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &:hover {
                    cursor: pointer;
                    &:before {
                        transform: translate(-50%, -50%) scale(1.2);
                    }
                }
                &:before {
                    background-image: url("/assets/icons/play-icon.png");
                    background-size: contain;
                    width: 90px;
                    height: 90px;
                    position: absolute;
                    z-index: 2;
                    content: "";
                    top:45%;
                    left:50%;
                    transition: transform 200ms ease-in-out;
                    transform: translate(-50%, -50%) scale(1);
                    border: 1px solid rgb(123, 123, 123);
                    border-radius: 100%;
                    opacity: 0.92;
                }
            }
        }
        .step-details {
            width: 100%;
            padding: 0 20px 20px 20px;
            display: flex;
            flex-direction: row;
            gap :32px;
            height: fit-content;
            .step-details--a {
                width: 50%;
                height:100%;
                .instructor-cta-container {
                    max-width: 300px;
                    position: relative;
                    .Lesson_Instructor--component {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    .panelCTA {
                        width: 100%;
                        margin-bottom: 20px;
                        // border: none;
                    }
            
                }
                .step-details--name {
                    font-size: 30px;
                    padding-bottom: 4px;
                    color: black;
                    font-weight: 500;
                }
                .step-details--description {
                    font-size: 20px;
                    color: black;
                    padding-bottom: 10px;
                    // overflow-y: scroll;
                    height: fit-content;
                }
            }
            .step-details--b {
                width: auto;
                display: flex;
                flex-direction: column;
                height: fit-content;
                // padding-right: 30px;
                // border-right: 1px solid rgba(255, 255, 255, 0.191);
                .step-details--container {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    width: 100%;
                    height: fit-content;
                    .stepRequirements--title {
                        font-size: 22px;
                        color: black;
                        height: fit-content;
                    }
                    .stepRequirements--container {
                        flex-direction: row;
                        flex-wrap: wrap;
                        display: flex;
                        gap: 13px;
                        // max-height: 150px;
                        overflow-y: auto;
                        width: 100%;        
                        height: fit-content;             
                    }
                }
            }
        }
        @media (max-width: 768px) { display: none;}
    }
}



  